<template>
    <validation-observer tag="div" ref="observer">
        <b-overlay :show="loading" no-wrap></b-overlay>
        <div v-if="!loading">
            <b-row>
                <b-col cols="12">
                    <b-card
                        border-variant="primary"
                        align="center"
                    >
                        <b-card-text>
                            <b-row>
                                <b-col class="text-left" cols="12">{{ $t('settings.database.anonymize_help') }}</b-col>
                            </b-row>
                            <b-row>
                                <b-col class="text-left">
                                    <b-button
                                        @click="anonymize"
                                        variant="danger"
                                        class="mb-2 mt-2"
                                    >
                                        <font-awesome-icon icon="eye-slash"/>
                                        {{ $t('settings.database.button.anonymize') }}
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-card-text>
                    </b-card>
                </b-col>

                <b-col cols="12">
                    <b-card
                        border-variant="primary"
                        align="center"
                    >
                        <b-card-text>
                            <b-row>
                                <b-col class="text-left" cols="12">{{ $t('settings.database.remove_instance') }}</b-col>
                            </b-row>
                            <b-row>
                                <b-col class="text-left">
                                    <b-button
                                        @click="removeInstance"
                                        variant="danger"
                                        class="mb-2 mt-2"
                                    >
                                        <font-awesome-icon icon="eye-slash"/>
                                        {{ $t('settings.database.button.remove_instance') }}
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-card-text>
                    </b-card>
                </b-col>
            </b-row>
        </div>
    </validation-observer>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'SettingsDatabase',
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            dumps: [],
        }
    },
    methods: {
        ...mapGetters('Dumps', ['getDumps']),
        shown() {
            this.loading = false
        },
        anonymize() {
            this.$confirm(
                {
                    message: this.$t('common.confirmations.common_confirm'),
                    button: {
                        no: this.$t('common.button.no'),
                        yes: this.$t('common.button.yes')
                    },
                    callback: confirm => {
                        if (confirm) {
                            this.$store.dispatch('Settings/anonymizeData', []).then((response) => {
                                this.commonJobsSave(response)
                            }).catch(errors => {
                                this.$refs['observer'].setErrors(errors)
                                this.showValidationError()
                            })
                        }
                    }
                }
            )
        },
        removeInstance() {
            this.$confirm(
                {
                    message: this.$t('common.confirmations.common_confirm'),
                    button: {
                        no: this.$t('common.button.no'),
                        yes: this.$t('common.button.yes')
                    },
                    callback: confirm => {
                        if (confirm) {
                            this.$store.dispatch('Settings/removeInstance', []).then((response) => {
                                this.commonJobsSave(response)
                            }).catch(errors => {
                                this.$refs['observer'].setErrors(errors)
                                this.showValidationError()
                            })
                        }
                    }
                }
            )
        },
    },
    mounted() {
        this.shown()
    }
}
</script>